import type { NavigationCategoryResponse } from '~/composables/types/api/searchDiscover/header'
import handleError from '~/composables/api/handleResponse'
import type { ApiResponse } from '~/composables/types/api/apiResponse'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'

export default async function getNavigation(param: string): Promise<NavigationCategoryResponse | ApiResponse | []> {
    const config = useRuntimeConfig()
    const oAuthEnabled = config.public.oAuthEnabled
    const { fetchSdApi, fetchSearchApi } = fetchOrderDetermination()

    if (oAuthEnabled === 'true') {
        const url = '/public/kategorien'
        try {
            const response = (await fetchSearchApi(url)) as NavigationCategoryResponse
            if (response.error) {
                return handleError<NavigationCategoryResponse>(response)
            }

            return {
                data: {
                    id: 'Category Tree (DE)',
                    type: 'navigations',
                    attributes: {
                        isActive: true,
                        name: 'Category Tree (DE)',
                        nodes: [
                            {
                                isActive: true,
                                title: 'Gesamtsortiment',
                                children: response,
                            },
                        ],
                    },
                },
            } as NavigationCategoryResponse
        } catch (error) {
            return handleError<NavigationCategoryResponse>(error)
        }
    }

    const url = `/navigations/${param}`

    try {
        const response = (await fetchSdApi(url)) as NavigationCategoryResponse
        if (response.error) {
            return handleError<NavigationCategoryResponse>(response)
        }

        return response.data.attributes.isActive ? response : []
    } catch (error) {
        return handleError<NavigationCategoryResponse>(error)
    }
}
