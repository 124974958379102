import getNavigation from '~/composables/api/searchDiscover/getNavigation'
import type { NavigationCategory } from '~/composables/types/api/searchDiscover/header'

interface paramsType {
    id: string
}

export default function (params: paramsType) {
    const navigationId = params.id
    const result = ref()

    const loadNavigation = async () => {
        result.value = await getNavigation(navigationId)
    }

    const mapData = (data: any, useInactive?: boolean): NavigationCategory[] =>
        data
            ?.filter((item: any) => (!useInactive ? item.isActive : true))
            .map((item: any) => ({
                ...item,
                id: item.resourceId,
                children: mapData(item.children, useInactive),
                url: parseNavigationUrl(item.url),
            })) ?? []

    const getNavigationCategories = (useInactive?: boolean): NavigationCategory =>
        mapData(result.value?.data?.attributes?.nodes, useInactive)[0]

    return {
        loadNavigation,
        getNavigationCategories,
        navigations: computed(() => result.value),
        nodes: computed(() =>
            result.value?.data?.attributes?.isActive ? mapData(result.value?.data?.attributes?.nodes) : []
        ),
    }
}
